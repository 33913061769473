import _defaultTo from "lodash/defaultTo";
import _isEmpty from "lodash/isEmpty";
import Cookies from "js-cookie";
import { decode } from "jsonwebtoken";

import getEnv from "./getEnv";

let _appPrefersImpersonator = false;

/**
 * Returns the auth URL where the user can authenticate themselves via OAuth.
 *
 * @returns {string} The URL where the user can authenticate.
 */
export function getOAuthUrl() {
	return getEnv( "AUTH_URL", "https://my.yoast.test/auth/yoast" );
}

/**
 * Checks whether the intendedDestination cookie is set.
 *
 * @returns {boolean} Whether or not the cookie was set.
 */
export function hasPeriLoginCookie() {
	return !! Cookies.get( "intendedDestination" );
}

/**
 * Sets the intendedDestination in the site-wide cookie containing the URL where the user intended to go.
 *
 * @returns {void}
 */
export function setPeriLoginCookie() {
	if ( hasPeriLoginCookie() === false ) {
		let intendedDestination = window.location.href;

		// If there is an UTM hash present on the URL; strip it!
		if ( window.location.hash.startsWith( "#utm_" ) ) {
			intendedDestination = intendedDestination.split( "#" )[ 0 ];
		}
		if ( window.location.search.startsWith( "?" ) ) {
			intendedDestination = intendedDestination.split( "?" )[ 0 ];
		}

		Cookies.set( "intendedDestination", intendedDestination );
	}
}

/**
 * Saves the redirect_to query parameter in the target destination cookie.
 *
 * @param {String} intendedDestination The intended destination.
 *
 * @returns {void}
 */
export function saveIntendedDestination( intendedDestination ) {
	Cookies.set( "intendedDestination", intendedDestination );
}

/**
 * Removes the intendedDestination from the site-wide cookie.
 *
 * @returns {void}
 */
export function removePeriLoginCookie() {
	Cookies.remove( "intendedDestination" );
}

/**
 * Redirects to the authentication URL.
 *
 * @returns {void}
 */
export function redirectToOAuthUrl() {
	setPeriLoginCookie();
	document.location.href = getOAuthUrl();
}

/**
 * Redirect the user to the login page while saving the current page as the intended destination after a successful login.
 *
 * @returns {void}
 */
export function redirectToLoginPage() {
	removePeriLoginCookie();
	setPeriLoginCookie();
	window.location.href = getEnv( "LOGIN_URL", "https://my.yoast.test/login" );
}

/**
 * Checks whether the redirect url is safe.
 *
 * @param {string} redirectUrl   The redirect url retrieved from the cookie.
 *
 * @returns {string}             The url the user should be redirected to.
 */
export function sanitizeRedirectUrl( redirectUrl ) {
	let redirectHostname;
	try {
		const parsedRedirectUrl = new URL( redirectUrl );
		redirectHostname = parsedRedirectUrl.hostname;
	} catch ( e ) {
		console.warn( "redirectURL is not a valid URL", redirectUrl );
	}

	if ( ! redirectHostname ) {
		return getEnv( "HOME_URL", "https://my.yoast.test" );
	}

	const allowedPostfixes = [
		"yoast.com",
		"yoast.test",
	];

	const isInWhitelist = allowedPostfixes.some( postfix => redirectHostname.endsWith( postfix ) );
	return isInWhitelist ? redirectUrl : getEnv( "HOME_URL", "https://my.yoast.test" );
}

/**
 * Gets the url the user should be redirected to after the login.
 *
 * @returns {string} The url the user should be redirected to.
 */
export function getRedirectUrl() {
	if ( hasPeriLoginCookie() ) {
		return sanitizeRedirectUrl( Cookies.get( "intendedDestination" ) );
	}
	return getEnv( "HOME_URL", "https://my.yoast.test" );
}

/**
 * Returns the URL where the user can reset their password.
 *
 * @returns {string} The URL where the user can reset their password.
 */
export function getPasswordResetUrl() {
	return getEnv( "PASSWORD_RESET_URL", "https://yoast.test/wp-login.php?action=lostpassword" );
}

/**
 * Determines whether or an access token is available.
 *
 * @returns {boolean} Whether or not an access token is available.
 */
export function hasAccessToken() {
	return !! Cookies.get( "access_token" );
}

/**
 * Sets whether the app prefers to perform actions on behalf of the impersonator (opposed to the impersonated user).
 *
 * @param {boolean} appShouldPreferImpersonator the impersonator should be preferred for this app.
 *
 * @returns {void}
 */
export function setAppImpersonatorPreference( appShouldPreferImpersonator ) {
	_appPrefersImpersonator = !! appShouldPreferImpersonator;
}

/**
 * Checks whether the app prefers to perform actions on behalf of the impersonator (opposed to the impersonated user).
 *
 * @returns {boolean} True if the impersonator should have preference.
 */
export function appPrefersImpersonator() {
	return !! _appPrefersImpersonator;
}

/**
 * Returns the access token known from the cookies.
 *
 * @param {boolean} preferImpersonator If a user is impersonated, setting this to true will yield the impersonator.
 *
 * @returns {string} The available access token.
 */
export function getAccessToken( preferImpersonator = false ) {
	const accessToken = _defaultTo( Cookies.get( "access_token" ), "" );
	if ( ! preferImpersonator ) {
		return accessToken;
	}

	const decoded = decode( accessToken, {} );
	if ( ! decoded ) {
		return accessToken;
	}

	return decoded.impersonatorToken || accessToken;
}

/**
 * Gets a list of role names of the current user.
 *
 * @param {boolean} preferImpersonator If a user is impersonated, setting this to true will yield the impersonator.
 *
 * @returns {string[]} The list of role names that the user has.
 */
export function getUserRoles( preferImpersonator = false ) {
	const accessToken = getAccessToken( preferImpersonator );

	if ( ! accessToken ) {
		return [];
	}

	const decoded = decode( accessToken, {} );
	if ( ! decoded ) {
		return [];
	}

	return decoded.roles || [];
}

/**
 * Returns the user ID from the JWT in the access_token cookie.
 *
 * @param {boolean} preferImpersonator If a user is impersonated, setting this to true will yield the impersonator.
 *
 * @returns {string} The known user ID, or empty if unset.
 */
export function getUserId( preferImpersonator = false ) {
	const accessToken = getAccessToken( preferImpersonator );

	if ( ! accessToken ) {
		return "";
	}

	const decoded = decode( accessToken, {} );
	if ( ! decoded ) {
		return "";
	}

	return decoded.accountId;
}

/**
 * Checks whether the access token has a valid JWT payload.
 *
 * @returns {boolean} True if the access token has a valid JWT payload, otherwise false.
 */
export function hasMalformedAccessToken() {
	return decode( getAccessToken(), {} ) === null;
}

/**
 * We used to set the accessToken on the my.yoast.com domain. Since we use JWTs, we want the full
 * .yoast.com to be able to access the cookie.
 *
 * This function can be removed after 2023-02-27 because all cookies with the old domain will have expired.
 *
 * @returns {void}
 */
export function cleanupOldAuthCookies() {
	Cookies.remove( "access_token" );
	Cookies.remove( "access_token", { domain: "my.yoast.com", path: "/" } );
	Cookies.remove( "access_token", { domain: "staging-my.yoast.com", path: "/" } );
	Cookies.remove( "access_token", { domain: "staging-plugins-my.yoast.com", path: "/" } );
	Cookies.remove( "access_token", { domain: "staging-platform-my.yoast.com", path: "/" } );
	Cookies.remove( "access_token", { domain: "staging-4-my.yoast.com", path: "/" } );
	Cookies.remove( "access_token", { domain: "staging-5-my.yoast.com", path: "/" } );
	Cookies.remove( "access_token", { domain: "my.yoast.test", path: "/" } );

	Cookies.remove( "access_token", { domain: ".my.yoast.com", path: "/" } );
	Cookies.remove( "access_token", { domain: ".staging-my.yoast.com", path: "/" } );
	Cookies.remove( "access_token", { domain: ".staging-plugins-my.yoast.com", path: "/" } );
	Cookies.remove( "access_token", { domain: ".staging-platform-my.yoast.com", path: "/" } );
	Cookies.remove( "access_token", { domain: ".staging-4-my.yoast.com", path: "/" } );
	Cookies.remove( "access_token", { domain: ".staging-5-my.yoast.com", path: "/" } );
	Cookies.remove( "access_token", { domain: ".my.yoast.test", path: "/" } );
}

/**
 * Gets the domain to track auth cookies on.
 *
 * @returns {string} The cookie domain.
 */
export function getCookieDomain() {
	const cookieDomain = process.env.REACT_APP_SHARED_COOKIE_DOMAIN;

	if ( cookieDomain ) {
		return cookieDomain;
	}

	return process.env.NODE_ENV === "development" ? ".yoast.test" : ".yoast.com";
}

/**
 * Removes cookies that are relevant for authentication
 *
 * @returns {void}
 */
export function removeCookies() {
	Cookies.remove( "access_token", { domain: getCookieDomain(), path: "/" } );
}

/**
 * Sets the access token auth cookie.
 *
 * @param {string} accessToken The token to set.
 *
 * @returns {void}
 */
export function setAuthCookie( accessToken ) {
	Cookies.set( "access_token", accessToken, { domain: getCookieDomain(), path: "/" } );
}

/**
 * Stops any running impersonation session and logs in the impersonator again.
 *
 * @returns {string} The access token of the impersonator.
 */
export function cancelImpersonation() {
	const accessToken = getAccessToken( true );
	removeCookies();
	setAuthCookie( accessToken );
	return accessToken;
}

/**
 * Get the yoast.com access OAuth token of a user who is already logged in on yoast.com
 *
 * @returns {Object} A promise containing the access token.
 */
export function fetchAccessToken() {
	return new Promise( ( resolve, reject ) => {
		if ( hasAccessToken() ) {
			return resolve( getAccessToken() );
		}

		const frame     = document.createElement( "IFrame" );
		frame.onload    = () => {
			const accessToken = getAccessToken();

			/**
			 * This means that the openId callback ( nestjs-server/src/controllers/login/YoastComOpenIdController.ts:68 )
			 * did not include an access token cookie.
			 */
			if ( _isEmpty( accessToken ) ) {
				reject( new Error( "We were not able to log you in, please try again a little bit later." ) );
				return;
			}

			resolve( accessToken );
		};
		frame.src       = getOAuthUrl();
		frame.className = "auth_frame";
		document.body.appendChild( frame );
	} );
}
