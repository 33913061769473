import React, { Fragment, useEffect, useState, useContext } from "react";
import { defineMessages, FormattedMessage, intlShape, injectIntl } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";
import { colors } from "@yoast/style-guide";
import { prepareInternalRequest, doRequest } from "shared-frontend/functions/api";
import { speak } from "@wordpress/a11y";
import _debounce from "lodash/debounce";

import { InstallContext } from "./InstallContext";
import SelectOrAddSite from "./SelectOrAddSite";
import { Button } from "../Button";
import Link from "../Link";
import Alert from "../Alert";
import NotCompatibleTYPO3Modal from "../modal/NotCompatibleTYPO3Modal";
import NoLicenseModal from "../modal/NoLicenseModal";
import stripCommonWrongSubdirectories from "../../functions/stripCommonWrongSubdirectories";

const messages = defineMessages( {
	downloadButton: {
		id: "install.downloadButton",
		defaultMessage: "Download {addon}",
	},
	installManually: {
		id: "install.installManually",
		defaultMessage: "Would you prefer to install the plugin manually, or maybe using Composer? You can find your products in {link}.",
	},
	yourAccountLink: {
		id: "install.youraccountlink",
		defaultMessage: "your MyYoast account",
	},
	siteSelectedForInstallation: {
		id: "install.siteSelectedForInstallation",
		defaultMessage: "You've selected a site to install your products on, use the install button to continue.",
	},
	siteDoesNotExist: {
		id: "install.siteDoesNotExist",
		defaultMessage: "We can't find {link} online. Please enter URL to the existing site.",
	},
	urlCommonWrongSubdirectoryWarning: {
		id: "install.urlCommonWrongSubdirectoryWarning",
		defaultMessage: `It appears you have included "{warning}" in your url. This is allowed
						but not necessary. We will save your site as: {link}.`,
	},
	nextSteps1: {
		id: "install.nextstep.firstLine",
		defaultMessage: "Next, let's get started on installing {addon} on your website!",
	},
	nextSteps2: {
		id: "install.nextstep.secondLine",
		defaultMessage: "Please provide the site you'd like to install it on.",
	},
} );


const AlignContentLeft = styled.div`
	text-align: left;
`;

const DownloadButton = styled( Button )`
	margin: 1em 0;
	background-color: ${ props => props.enabledStyle ? colors.$color_pink_dark : colors.$color_grey_disabled };
`;

const ManualInstallContent = styled.p`
	font-size: 0.9em;
	color: #404040;
`;


const SubdirectoryWarning = styled.div`
	margin-top: 16px;
`;

/**
 * Install a subscription on a site flow for desktop.
 *
 * @param {object} props The properties.
 *
 * @returns {ReactComponent} The rendered component.
 */
const InstallDesktop = ( props ) => {
	const { setLinkedSite, setDownloadZip, invoiceNumberParam } = useContext( InstallContext );
	const [ selectedSite, setSelectedSite ]       = useState( "" );
	const [ sendLinkRequest, setSendLinkRequest ] = useState( false );

	const [ isNoLicensesModalOpen, setIsNoLicensesModalOpen ]                 = useState( false );
	const [ isNotCompatibleTYPO3ModalOpen, setIsnotCompatibleTYPO3ModalOpen ] = useState( false );

	const [ subdirectoryWarning, setSubdirectoryWarning ] = useState( "" );
	/**
	 * Closes no licenses modal.
	 *
	 * @returns {void}
	 */
	function closeNoLicensesModal() {
		setIsNoLicensesModalOpen( false );
	}

	/**
	 * Closes site not compatibale TYPO3 modal.
	 *
	 * @returns {void}
	 */
	function closeNotCompatibleTYPO3Modal() {
		setIsnotCompatibleTYPO3ModalOpen( false );
	}

	const speakInstallReadyMessageDebounced = _debounce(
		/**
		 * Announces that the products can be installed on the site.
		 *
		 * @returns {void}
		 */
		() => {
			if ( selectedSite ) {
				const message = props.intl.formatMessage( messages.siteSelectedForInstallation );
				speak( message );
			}
		},
		1000,
	);

	useEffect( () => {
		speakInstallReadyMessageDebounced();
		/**
		 * Make sure the speak is stopped when the component unmounts.
		 *
		 * @returns {void}
		 */
		 return () => {
			speakInstallReadyMessageDebounced.cancel();
		};
	}, [ selectedSite ] );

	useEffect( () => {
		let premiumPlugin;

		if ( props.plugins.length ) {
			premiumPlugin = props.plugins.find( plugin => plugin.glNumber === "82120" );
		}

		if ( premiumPlugin ) {
			const downloadUrl = premiumPlugin.downloads.map( download => download.file )[ 0 ];

			setDownloadZip( downloadUrl );
		}
	}, [ props.plugins.length ] );

	/**
	 * Sends a POST request to link the provided site URL to a subscription.
	 * Sets the linkedSite variable to the provided URL.
	 *
	 * @param  {string} url The selected site URL.
	 * @param  {string} invoiceNumber The invoice number.
	 *
	 * @returns {object} The site with the subscription relation.
	 */
	async function linkSiteToSubscription( url, invoiceNumber ) {
		const request = prepareInternalRequest( "Subscriptions/link-site/", "POST", { url, invoiceNumber } );

		try {
			const response = await doRequest( request );

			setLinkedSite( selectedSite );

			return response;
		} catch ( error ) {
			setSendLinkRequest( false );

			switch ( error.message ) {
				case "No valid subscriptions found.":
					setIsNoLicensesModalOpen( true );
					break;
				case "Site type is not supported.":
					setIsnotCompatibleTYPO3ModalOpen( true );
					break;
				default:
					// Do nothing.
			}

			return false;
		}
	}

	/**
	 * Sets url on change of inputfield
	 *
	 * @param {string} url The input url from the client
	 *
	 * @returns {void} The stripped url.
	 */
	function onSiteUrlChange( url ) {
		const strippedUrlResult = stripCommonWrongSubdirectories( url );
		setSubdirectoryWarning( strippedUrlResult.strippedSubdirectory );
		setSelectedSite( strippedUrlResult.url );
	}

	useEffect( () => {
		if ( sendLinkRequest ) {
			linkSiteToSubscription( selectedSite, invoiceNumberParam );
		}
	}, [ sendLinkRequest ] );

	const addon = "Yoast SEO Premium";

	const myYoastAccountLink = <Link to={ "/downloads" } id="install-manual-context">
		<FormattedMessage { ...messages.yourAccountLink } />
	</Link>;

	return (
		<Fragment>
			<p>
				<FormattedMessage { ...messages.nextSteps1 } values={ { addon } } />
				<br />
				<FormattedMessage { ...messages.nextSteps2 } />
			</p>
			<AlignContentLeft>
				<SelectOrAddSite
					existingSites={ props.sites }
					onSiteUrlChange={ onSiteUrlChange }
					pluginName={ addon }
				/>
			</AlignContentLeft>
			{ subdirectoryWarning &&
			<SubdirectoryWarning>
				<Alert type="info" dismissable={ false } cookieName="installOtherPluginsInfoAlert">
					<FormattedMessage
						{ ...messages.urlCommonWrongSubdirectoryWarning }
						values={ { link: selectedSite, warning: subdirectoryWarning } }
					/>
				</Alert>
			</SubdirectoryWarning>
			}

			<DownloadButton
				id="install-addon-download-action"
				className="install-addon-download-button"
				onClick={ () => setSendLinkRequest( true ) }
				enabledStyle={ !! selectedSite  }
				disabled={ ! selectedSite }
				aria-disabled={ ! selectedSite }
			>
				<FormattedMessage { ...messages.downloadButton } values={ { addon } } />
			</DownloadButton>

			<ManualInstallContent>
				<FormattedMessage
					{ ...messages.installManually }
					values={ { link: myYoastAccountLink } }
				/>
			</ManualInstallContent>

			{ isNoLicensesModalOpen && <NoLicenseModal closeModal={ closeNoLicensesModal } /> }

			{ isNotCompatibleTYPO3ModalOpen && <NotCompatibleTYPO3Modal closeModal={ closeNotCompatibleTYPO3Modal } /> }
		</Fragment>
	);
};

InstallDesktop.propTypes = {
	intl: intlShape.isRequired,
	sites: PropTypes.array,
	loadingSites: PropTypes.bool,
	submitAddSite: PropTypes.func.isRequired,
	plugins: PropTypes.array,
};

InstallDesktop.defaultProps = {
	sites: [],
	plugins: [],
	loadingSites: false,
};

export default injectIntl( InstallDesktop );
